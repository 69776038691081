<template>
  <main v-if="ListTeam.status != 'false'" class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue"><i class="fas fa-users margin-r" aria-hidden="true"></i>Manage members in team</h3>
          <div class="card card-cover text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <div style="display:inline !important">
                <div class="container">
                  <form ref="TeamForm" @submit.prevent="PostMembersTeamsPost()" class="row g-3 justify-content-center align-items-center">
                    <div class="col-md-12" align="left">
                      <p><i class="fas fa-users margin-r fa-lg" aria-hidden="true"></i> {{ListTeam.name}}</p>
                    </div>
                    <div class="col-md-12" align="left">
                      <label for="validationDefault03" class="form-label">Members List</label>
                      <Multiselect v-model="MultiSelectMembers.value" v-bind="MultiSelectMembers">
                        <template v-slot:tag="{ option, handleTagRemove, disabled }">
                          <div  class="multiselect-tag is-user">
                            <img :src="option.icon">
                            <a :style="{'color':option.color}">{{ option.label }}</a>
                            <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                              <span class="multiselect-tag-remove-icon"></span>
                            </span>
                          </div>
                        </template>
                        <template v-slot:option="{ option }">
                          <img class="character-option-icon" :src="option.icon"> {{ option.label }}  
                        </template>
                      </Multiselect>
                    </div>
                    <div class="d-flex justify-content-end mt-3 m-1">
                      <a class=" btn btn-danger"  style="margin-right: 5px;" @click="$router.go(-1)"> Go Back</a>
                      <button :disabled="this.Form.submit" class="button-1 btn btn-primary" type="submit">Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import Api from '@/services/Request'; 
  import Multiselect from '@vueform/multiselect'

  export default
  {
    name: 'AddMemberTeam',
    components: 
    {
      Multiselect,
    },
    data: () => (
    {
      MultiSelectMembers: 
      {
        mode: 'tags',
        value: [],
        closeOnSelect: false,
        options: [],
        searchable: true,
        createTag: false,
        inputType: "email",
        required: false,
        placeholder: "example@domain.com",
        hideSelected: true,
        showOptions : true
      },
      ListMembers: 
      {
        status:"false",
        list: {}
      },
      Form: 
      {
        submit: false
      },
      MembersTeamsPost: 
      {
        id_team:"",
        status:"",
        message:"",
        members: [],
      },
      ListTeam: 
      {
        id:"",
        name:"",
        status:"false"
      }
    }),
    methods: 
    { 
      PostMembersTeamsPost() 
      {
        this.Form.submit = true

        for (var i = 0; i < this.MultiSelectMembers.value.length; i++)
        {  
          this.MembersTeamsPost.members.push(this.MultiSelectMembers.value[i])
        }
     
        Api.post("/teams-members/insert",this.MembersTeamsPost).then((result) => 
        {
          if(result.data.status == "true")
          {
            this.MembersTeamsPost.status = result.data.status
            this.MembersTeamsPost.message = result.data.msg;
         
            this.$moshaToast( ''+this.$filters.OnlyLetters(this.MembersTeamsPost.message)+'', { type: 'default', position:'bottom-right', transition:'zoom' })
  
            setTimeout( () => 
            {
              this.MultiSelectMembers.value = []
              this.MultiSelectMembers.options = []
              this.MembersTeamsPost.members = []
              this.MembersTeamsPost.message = ""
              this.MembersTeamsPost.status = ""
              this.GetMembers()
              this.Form.submit = false
            }, 5000);
          }
        })    
      },
      GetTeam() 
      {
        Api.get('/team/list/' + this.$route.params.id).then((result) => 
        {
          this.ListTeam.status = result.data.status
      
          if(this.ListTeam.status == "true")
          {
            this.ListTeam.name =  result.data.team[0].name
            this.MembersTeamsPost.id_team =  result.data.team[0].id
          }
        })
      },
      GetMembers() 
      {
        Api.post("/member/list/","").then((result) => 
        {
          this.ListMembers.status = result.data.status

          if(this.ListMembers.status == "true")
          {
            this.ListMembers.list =  result.data.member

            for (var i = 0; i <this.ListMembers.list.length; i++)
            {
              if(this.ListMembers.list[i].description == "Worker")
              {
                if(this.ListMembers.list[i].id_team == null )
                {
                  var disabled = false
                }
                else 
                { 
                  var disabled = true 
                }
            
                var options =  {value: this.ListMembers.list[i].id_session, label: this.ListMembers.list[i].email, icon: this.ListMembers.list[i].picture, disabled: disabled }
                this.MultiSelectMembers.options.push(options)
              }
            }

            Api.get('/teams-members/list/' + this.$route.params.id).then((result) => 
            {
              if(result.data.status == "true")
              {
                if(result.data.team !="")
                {
                  var members = []
                  members = result.data.team

                  for (var i = 0; i < members.length; i++)
                  {
                    if(members[i].description == "Worker")
                    {
                      var options = members[i].id_session
                      this.MultiSelectMembers.value.push(options)
                    }
                  }
                }
              }
            })
          }
        })

        this.Form.submit = false
      }
    },
    mounted()
    {
      this.GetTeam()
      this.GetMembers()
    }
  }
</script>

<style>
  .mosha__toast__content__text 
  {
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 700;
    white-space:pre;
  }
</style>
