<template>
   <HeaderDash />
   <SideMenu /> 
   <AddMemberTeam /> 
</template>
<script>
// Importando los componententes pertenecientes a esta vista
import HeaderDash from '@/components/team/dashboard/HeaderDash.vue'
import SideMenu from '@/components/team/dashboard/SideMenu.vue'
import AddMemberTeam from '@/components/team/dashboard/modules/team/AddMember-Team.vue'

export default 
{
  name: 'AddMember-Team',
  components: 
  {
    HeaderDash,
    SideMenu,
    AddMemberTeam
  }
}
</script>
<style>


</style>
